import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/index')
  },
  {
    path: '*',
    component: () => import('../views/page-error')
  },
  {
    path: '/landtoken',
    name: 'Landtoken',
    component: () => import('../views/landtoken')
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import('../views/team')
  },
  {
    path: '/page-privacy',
    name: 'Privacy',
    component: () => import('../views/page-privacy')
  },
  {
    path: '/page-terms',
    name: 'Terms',
    component: () => import('../views/page-terms')
  },
  {
    path: '/social',
    name: 'Community',
    component: () => import('../views/contact')
  },
  {
    path: '/partner',
    name: 'Partners',
    component: () => import('../views/partner')
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router

import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import VueI18n from 'vue-i18n'
import getBrowserLocale from "./i18n"

import enFile from './lang/en.json'
import koFile from './lang/ko.json'

import "../public/css/materialdesignicons.min.css";
import 'swiper/swiper-bundle.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/xi-ticker.css'
import './assets/xi-ticker.js'

import { BootstrapVue } from 'bootstrap-vue'
var VueScrollTo = require('vue-scrollto');
import Scrollspy from 'vue2-scrollspy';
import VueYoutube from 'vue-youtube'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueMasonry from 'vue-masonry-css'

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

import flamelink from 'flamelink'
// import 'flamelink/storage';
import 'flamelink/cf/content';
import 'flamelink/cf/storage';

import "vue-mailchimp-email-signup-form/dist/vue-mailchimp-email-signup-form.css";

import { VueMailchimpEmailSignupForm } from "vue-mailchimp-email-signup-form";
Vue.component("vue-mailchimp-email-signup-form", VueMailchimpEmailSignupForm);

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyC4Ynz4x02wc-dkMauNigQ4EsyRebCMGs4",
    authDomain: "landbox-landingcms.firebaseapp.com",
    databaseURL: "https://landbox-landingcms-default-rtdb.firebaseio.com",
    projectId: "landbox-landingcms",
    storageBucket: "landbox-landingcms.appspot.com",
    messagingSenderId: "906287594394",
    appId: "1:906287594394:web:40bbb2c519e99a0f041823",
    measurementId: "G-GYNSHWKGX0"
  };

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
const firebaseApp = firebase.initializeApp(firebaseConfig)

const flapp = flamelink({
  firebaseApp, // required
  dbType: 'cf', // can be either 'rtdb' or 'cf' for Realtime DB or Cloud Firestore
  env: 'production', // optional, default shown
  precache: true // optional, default shown. Currently it only precaches "schemas" for better performance
})

// Ensure app is available on all vue instances
Vue.prototype.$flamelinkApp = flapp;

// Ensure app is available globally - useful for querying data not used in a view (eg. router setup, etc)
Vue.flamelinkApp = flapp;

Vue.use(VueScrollTo, {
  duration: 3000,
  easing: "ease"
});

Vue.use(BootstrapVue);
Vue.use(Scrollspy);
Vue.use(VueYoutube);
Vue.use(vueVimeoPlayer);
Vue.use(VueMasonry);
Vue.use(VueI18n);

const messages = {
  en: enFile,
  ko: koFile,
}

const i18n = new VueI18n({
  locale: getBrowserLocale({ countryCodeOnly: true }), // set locale
  messages, // set locale messages
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
